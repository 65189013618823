//Aktiv Grotesk
@font-face {
    font-family: 'Aktiv Grotesk Light';
    src: url('../assets/fonts/aktiv-grotesk/AktivGrotesk_Lt.ttf');
}

@font-face {
    font-family: 'Aktiv Grotesk Medium';
    src: url('../assets/fonts/aktiv-grotesk/AktivGrotesk_Md.ttf');
}

@font-face {
    font-family: 'Aktiv Grotesk Bold';
    src: url('../assets/fonts/aktiv-grotesk/AktivGrotesk_Bd.ttf');
}

body {
    --aktiv-grotesk-light: 'Twemoji Country Flags', 'Aktiv Grotesk Light';
    --aktiv-grotesk-medium: 'Twemoji Country Flags', 'Aktiv Grotesk Medium';
    --aktiv-grotesk-bold: 'Twemoji Country Flags', 'Aktiv Grotesk Bold';
}
