@import '../../../scss/mixins';

.loader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;
    left: 0;
    top: 0;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    .loader-container {
        position: relative;
        .logo {
            position: absolute;
            height: 15px;
            left: 50%;
            top: 50%;
            margin-top: -8px;
            margin-left: -75px;
        }
    }
}

.custom-style {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    left: 0;
    top: 0;
    background-color: var(--white);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .loader-container {
        margin-top: 20%;
    }

    @include mobile() {
        .loader-container {
            margin-top: 40%;
            padding-top: 20%;
        }
    }

    @include tablet() {
        .loader-container {
            margin-top: 40%;
        }
    }
}
