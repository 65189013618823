@mixin transition($time) {
    transition: $time;
    -webkit-transition: $time;
    -moz-transition: $time;
}
$large-desktpo-width: 1600px;
$max-content-width: 1440px;
$small-desktop: 1200px;
$desktop: 1025px;
$tablet-ends: 1024px;
$tablet-starts: 768px;
$mobile-ends: 767px;
$mobile-portrait: 480px;

// Responsiveness
@mixin large-desktop {
    @media screen and (min-width: $large-desktpo-width) {
        @content;
    }
}
@mixin small-desktop {
    @media screen and (min-width: $desktop) and (max-width: $small-desktop) {
        @content;
    }
}

@mixin small-desktop-starts {
    @media screen and (min-width: $small-desktop) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $desktop) {
        @content;
    }
}

@mixin tablet-only {
    @media screen and (min-width: $tablet-starts) and (max-width: $tablet-ends) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: $tablet-ends) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: $mobile-ends) {
        @content;
    }
}

@mixin small-mobile {
    @media screen and (max-width: $mobile-portrait) {
        @content;
    }
}

@mixin scrollbar($color) {
    overflow-y: scroll;
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $color;
    }
}

@mixin title($color) {
    .sub-head {
        color: $color;
    }
    h3 {
        color: $color;
        &::after {
            background-color: $color;
        }
    }
}

@mixin background-cover {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin wrapper {
    padding: 0px 145px;
    max-width: 1440px;
    margin: auto;
    @include tablet() {
        padding: 0px 30px;
    }
    @include mobile {
        padding: 0px 20px;
    }
}

@mixin full-bleed($background-color) {
    box-shadow: 0 0 0 100vmax $background-color;
    clip-path: inset(0 -100vmax);
}

@mixin d-flex-justify-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
