body {
    --black: #000000;
    --primary-black: #151515;
    --primary-black-hover: #333333;
    --primary-black-active: #666666;
    --primary-black-disabled: #aaaaaa;
    --primary-navy-blue: #147bd1;
    --brand-light-blue: #f3f8fd;
    --white: #ffffff;
    --dark-gray: #7e7e7e;
    --light-gray: #e8e8e8;
    --grey-disabled: #a6a4a4;
    --gray: #c4c4c4;
    --coral-pink: #f87e79;
    --magenta: #c55497;
    --purple: #8c60a3;
    --yellow: #ffd790;
    --indigo: #4e67aa;
    --darker-blue: #388cc3;
    --slightly-lighter-blue: #43aac5;
    --moonstone-blue: #66aac3;
    --lighter-blue: #34a9c0;
    --light-pink: #f5f5ff;
    --sky-blue: #b3cfe4;
    --light-red: #bf3d36;
    --light-red-error: #fc0000;
    --light-ash: rgba(245, 227, 226, 0.7);
    --cerulean-frost: #61a8c1;
    --tickle-pink: #ff86b5;
    --purple-majesty: #8a6ca9;
    --flavescent: #fbe298;
    --bright-gray: #e8f0f7;
    --light-black: rgba(0, 0, 0, 0.7);
    --medium-gray: #4c4c4c;
    --medium-blue: #4493c7;
    --azureish-white: #d9eef3;
    --light-white: rgb(245 245 245 / 75%);
    --white-smoke: #f5f5f5;
    --medium-black: rgba(0, 0, 0, 0.5);
    --v-light-black: rgba(0, 0, 0, 0.12);
    --v-light-gray: #e4e4e4;
    --isabelline: #f8ebeb;
    --medium-light-gray: rgba(0, 0, 0, 0.2);
    --light-dark-blue: rgba(56, 141, 196, 1);
    --nickel: #737373;
    --lighter-gray: rgba(232, 240, 247, 0.5);
    --dark-medium-gray: rgba(0, 0, 0, 0.6);
    --dark-blue-medium: #673ab7;
    --black-gray: #4c4c4c;
    --light-black-grey: rgba(0, 0, 0, 0.1);
    --light-grey: rgba(0, 0, 0, 0.75);
    --success-text: #22c55e;
    --shodow-black: rgb(0 0 0 / 12%);
    --light-blue: rgb(243, 248, 253);
    --linear-gradient: linear-gradient(
        180deg,
        #68abe4 0%,
        #529ede 37.5%,
        #0671ca 100%
    );
    --light-orange: #ffb25b;
    --brand-blue: #126db9;
    --dark-purple: #ab47bc;
}
